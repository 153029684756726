import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
// import { Error } from "./screens/Error";
import { HomeScreen } from "./screens/HomeScreen";
import Register from "./screens/Register";
import Login from "./screens/Login";
import PasswordReset from "./screens/PasswordReset";
import Otp from "./screens/Otp";
import NewPassword from "./screens/NewPassword";
import Dashboard from "./screens/Dashboard";
import SelfService from "./screens/SelfService";
import SimCard from "./screens/SimCard";
import Esim from "./screens/Esim";
import Wifi from "./screens/Wifi";
import PowerBank from "./screens/PowerBank";
import { Onboarding } from "./screens/Onboarding";
import PrivacyPolicy from "./screens/PrivacyPolicy";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/selfservice" element={<SelfService />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/simcard" element={<SimCard />} />
        <Route path="/esim" element={<Esim />} />
        <Route path="/wifi" element={<Wifi />} />
        <Route path="/power-bank" element={<PowerBank />} />
        <Route path="/checkout/:id" element={<Onboarding />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="*" element={<Error />} /> */}
      </Routes>
    </div>
  );
}

export default App;
