import React, {useEffect} from "react";
import { useLocation } from 'react-router-dom';
import NavBars from "../components/NavBars";
import ad from "../vdu/video.mp4";
import customerReviews from "../vdu/review.mp4";
import customerReviews2 from "../vdu/review2.mp4";
import sim1 from "../img/sim1.jpg";
import sim2 from "../img/sim2.jpg";
import sim3 from "../img/sim3.jpg";
import sim4 from "../img/sim4.jpg";
import sim5 from "../img/sim5.jpg";
import sim6 from "../img/sim6.jpg";
import sim7 from "../img/sim7.jpg";
import sim8 from "../img/sim8.jpg";
import sim9 from "../img/sim9.jpg";
import sim10 from "../img/sim10.jpg";
import esim1 from "../img/E-simoneweek.jpg";
import esim2 from "../img/E-simtwoweeks.jpg";
import esim3 from "../img/E-simthreeweeks.jpg";
import esim4 from "../img/E-simonemonth.jpg";
import esim5 from "../img/3gbesimparkage.jpg";
import esim6 from "../img/1.5gbesimparkage.jpg";
import wifi1 from "../img/mifi/5.jpeg";
import wifi2 from "../img/mifi/1.jpeg";
import wifi3 from "../img/mifi/2.jpeg";
import wifi4 from "../img/mifi/3.jpeg";
import wifi5 from "../img/mifi/4.jpeg";
import powerBank1 from "../img/powerbanks/1.jpeg";
import powerBank2 from "../img/powerbanks/2.jpeg";
import powerBank3 from "../img/powerbanks/3.jpeg";
import powerBank4 from "../img/powerbanks/4.jpeg";
import powerBank5 from "../img/powerbanks/5.jpeg";
import Button from '@mui/material/Button';
import { Footer } from "../components/Footer";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


export const HomeScreen = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#pricing') {
      const pricingSection = document.getElementById('pricing');
      if (pricingSection) {
        pricingSection.scrollIntoView();
      }
    } else if (location.hash === '#about'){
      const aboutSection = document.getElementById('about');
      if(aboutSection){
        aboutSection.scrollIntoView();
      }
    }else if (location.hash === '#contact'){
      const contactSection = document.getElementById('contact');
      if(contactSection){
        contactSection.scrollIntoView();
      }
    }
  }, [location]);

  return (
    <div >
        <NavBars/>
        <div className="container mb-5 spacer" id="home">
          <div className="banner">
            <div className="row">
              <div className="col-md-5">
                <div className="banner-text" >
                  <h1>
                    Experience seamless connectivity with Buy SIM & Connect GH!
                  </h1>
                  <a href="/register"><Button variant="contained" style={{backgroundColor: "#00239F", color: "#ffffff", fontWeight: "bolder"}}>Get Started</Button></a>
                </div>
              </div>
              <div className="col-md-7">
                <div className="banner-video" >
                  <video src={ad} autoPlay loop muted  ></video>
                </div>
              </div>
            </div>
          </div>
          <div className="packages">
            <div className="container">
            <h2 className="text-center mt-5" >Products</h2>
              <div className="row">
                <div className="col-sm py-4 pb-3">
                  <div className="card shadow service border-0">
                    <div id="carouselExampleAutoplaying3" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={sim1} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim2} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim3} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim4} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim5} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim6} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim7} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim8} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim9} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={sim10} className="d-block w-100" alt="..."/>
                        </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                    <div className="card-body text-center">
                      <h5 className="card-title">SIM Card Sale</h5>
                      <p className="card-text">Buy a SIM card for your stay in Ghana.</p>
                      <a href="./simcard" className="btn btn-primary ">More</a>
                    </div>
                  </div>
                  
                </div>
                <div className="col-sm py-4">
                  <div className="card shadow service border-0">
                    <div id="carouselExampleAutoplaying3" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={esim1} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={esim2} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={esim3} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={esim4} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={esim5} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={esim6} className="d-block w-100" alt="..."/>
                        </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying3" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                    <div className="card-body text-center">
                      <h5 className="card-title">E-SIM Sale</h5>
                      <p className="card-text">Buy an E-SIM for your stay in Ghana.</p>
                      <a href="./esim" className="btn btn-primary">More</a>
                    </div>
                  </div>
                  
                </div>
                <div className="col-sm py-4">
                    <div className="card shadow service border-0">
                    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={wifi1} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={wifi2} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={wifi3} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={wifi4} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={wifi5} className="d-block w-100" alt="..."/>
                        </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                      <div className="card-body text-center">
                        <h5 className="card-title">WiFi Device</h5>
                        <p className="card-text">Buy a Wifi Device for your stay in Ghana.</p>
                        <a href="./wifi" className="btn btn-primary">More</a>
                      </div>
                    </div>
                </div>
                <div className="col-sm py-4">
                  <div className="card shadow service border-0">
                    <div id="carouselExampleAutoplaying2" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={powerBank1} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={powerBank2} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={powerBank3} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={powerBank4} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                          <img src={powerBank5} className="d-block w-100" alt="..."/>
                        </div>
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying2" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying2" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                    <div className="card-body text-center">
                      <h5 className="card-title">Power Bank</h5>
                      <p className="card-text">Buy a Power bank for your stay in Ghana.</p>
                      <a href="./power-bank" className="btn btn-primary">More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pricing mt-5" id="pricing">
            <div className="container">
            <h2 className="text-center" >Pricing for SIM Cards and E-SIM</h2>
              <div className="row text-center">
                <div className="col-sm-3 seven shadow">
                  <h4>Subscription for 7 days is $3.50 per day.</h4>
                </div>
                <div className="col-sm-3 fourteen shadow">
                  <h4>Subscription for 14 days is $3.00 per day.</h4>
                </div>
                <div className="col-sm-3 twentyone shadow">
                  <h4>Subscription for 21 days is $2.50 per day.</h4>
                </div>
                <div className="col-sm-3 upwards shadow">
                  <h4>Subscription for 28 days and above is $1.70 per day.</h4>
                </div>
                
                {/* <h4 className="text-danger text-center shadow p-2 mt-4" >Note: An additional <b>$5.00</b>  will be added as a holding fee, which will be refunded once you return the SIM card.</h4> */}
              </div>
            </div>
            
          </div>
          <div className="about mt-5" id="about">
            <div className="container">
            <h2 className="text-center" >About</h2>
              <div className="row">
                <div className="col-sm mission shadow">
                  <h3 className="text-center">Mission Statement</h3>
                  <h5>Our mission is to provide high quality customer service to our clients.</h5>
                </div>
                <div className="col-sm vision shadow">
                  <h3 className="text-center">Vision Statement</h3>
                  <h5>Provide easy and fast connection to travelers on arrival in Ghana.</h5>
                </div>
              </div>
            </div>
            
          </div>
          <div className="reviews mt-5">
            <div className="container">
              <h2 className="text-center" >Customer Reviews</h2>
              <div className="row">
                <div className="col-sm-6">
                  <video style={{width: '-webkit-fill-available'}} src={customerReviews} controls ></video>
                </div>
                <div className="col-sm-6">
                  <video style={{width: '-webkit-fill-available'}} src={customerReviews2} controls ></video>
                </div>
              </div>
              <div className="text-end">
              <a href="https://www.youtube.com/@RentsimConnect" target="_blank" className="btn btn-primary" rel="noreferrer">View More</a>
              </div>
            </div>
          </div>
          <div className="contact mt-5" id="contact">
            <div className="container">
              <h2 className="text-center"  >Get in Touch</h2>
              <div className="row">
                <div className="col-sm shadow p-3">
                  <div>
                    <h4>Address:</h4>
                    <p className="mb-0">NII SAI GBEHE LN</p>
                    <p className="mb-0">GD-184-1768</p>
                    <p>AMERICAN HOUSE, EAST LEGON</p>
                  </div>
                  <div>
                    <h4>Phone:</h4>
                    <p> <span><CallIcon  style={{color: "#00239F"}} /></span> <a href="tel:+233206983015" target="_blank" rel="noreferrer">+233 20 698 3015</a></p>
                  </div>
                  <div>
                    <h4>WhatsApp:</h4>
                    <p className="mb-0" > <span><WhatsAppIcon  style={{color: "#00239F"}} /></span> <a href="https://wa.me/0206983015" target="_blank" rel="noreferrer">+233 20 698 3015</a></p>
                    <p className="mb-0" > <span><WhatsAppIcon  style={{color: "#00239F"}} /></span> <a href="https://wa.me/0570004029" target="_blank" rel="noreferrer">+233 570 004 029</a></p>
                    <p className="mb-0" > <span><WhatsAppIcon  style={{color: "#00239F"}} /></span> <a href="https://wa.me/0243816162" target="_blank" rel="noreferrer">+233 243 816 162</a></p>
                    <p> <span><WhatsAppIcon  style={{color: "#00239F"}} /></span> <a href="https://wa.me/13177858067" target="_blank" rel="noreferrer">+1 (317) 785-8067</a></p>
                  </div>
                  <div>
                    <h4>Email:</h4>
                    <p> <span><EmailIcon style={{color: "#00239F"}} /></span> <a href="mailto:info@rentsimconnect.com" target="_blank" rel="noreferrer">info@rentsimconnect.com</a></p>
                  </div>

                </div>
                <div className="col-sm map shadow">
                <iframe title="Address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.4763884435424!2d-0.15110472485994314!3d5.6439947327582445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf84aac3a61219%3A0x63a4e9e43d47adce!2sJVV2%2BHHX%20american%20house%2C%20Boundary%20Rd%2C%20Accra!5e0!3m2!1sen!2sgh!4v1682319613031!5m2!1sen!2sgh" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
       
        
    </div>
  );
};
