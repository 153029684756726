import React, {useEffect, useContext} from 'react'
import DashNav from '../components/DashNav'
import { AuthContext } from '../context/AuthContext';

export default function Dashboard() {
  const {logout} = useContext(AuthContext);

  useEffect(() => {
    function startTimer() {
      var timeLeft = 29 * 60;
  
      var timer = setInterval(function () {
        timeLeft--;
        if (timeLeft <= 0) {
          clearInterval(timer);
          logout();
        }
      }, 1000);
    }
  
    startTimer();
  }, [logout]);

  return (
    <div>
        <DashNav/>
    </div>
  )
}
