import React, { createContext, useState, useEffect } from "react";
import ToastService from "react-material-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const toast = ToastService.new({
  place: "topRight",
  duration: 1,
  maxCount: 1,
});


export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBuy, setIsLoadingBuy] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [productData, setProductData] = useState([]);
  const baseURL = 'https://api.rentsimconnect.com';
  const publicKey = 'pk_live_461931560a7e6c6fde3c9d69f7fefbc5de63b25f';
  const secretKey = 'sk_live_1613ec894c4d9910af134233b6142c31239775a4';
  
  
  let navigate = useNavigate();
  let response;
  let passwordToken;
  let token;
  


  const register = async (
    first_name,
    last_name,
    email,
    sex,
    country,
    // fmtDob,
    // passport_number,
    contact,
    // passport_photo,
    profile_photo,
    referral_code,
    password,
  ) => {
    let data = new FormData();
    data.append("first_name", first_name);
    data.append("last_name", last_name);
    data.append("email", email);
    data.append("sex", sex);
    data.append("country", country);
    // data.append("dob", fmtDob);
    // data.append("passport_number", passport_number);
    data.append("contact", contact);
    // data.append("passport_photo", passport_photo);
    data.append("profile_photo", profile_photo);
    data.append("referral_code", referral_code);
    data.append("password", password);

    setIsLoading(true);
    try {
      response = await axios.post(`${baseURL}/customer/signup`, data);
      if (response.status === 201) {
        toast.success("Registration successful");
        navigate("/login");
      } else if(response.status === 208){
        toast.error("Customer already exist");
      }else {
        console.log(response);
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error.response.status === 422) {
        toast.error("All fields are required");
      }else {
        toast.error("Something went wrong");
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const verify = async (email, otp, event) => {
    event.preventDefault();
    let data = {
      email: email,
      otp: otp,
    };
    setIsLoading(true);
    let whereToGo = localStorage.getItem("pathName");
    try {
      response = await axios.post(`${baseURL}/customerverify`, data);
      if (response.status === 200) {
        let userInfo = response.data;
        setUserInfo(userInfo);
        setIsLoggedIn(true);
        sessionStorage.setItem("token", userInfo.access_token);
        toast.success("Verification successful");
        if (whereToGo === "/esim") {
          navigate("/esim");
        }else if (whereToGo === "/simcard"){
          navigate("/simcard");
        }else if (whereToGo === "/wifi"){
          navigate("/wifi");
        }else if (whereToGo === "/power-bank"){
          navigate("/power-bank");
        }else if (whereToGo === "/password-reset"){
          navigate("/new-password");
        }
        else if (whereToGo === "/login"){
          navigate("/");
        }
      } else {
        console.log(response);
        toast.error("Verification not successful");
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error("Invalid OTP");
      } else {
        toast.error("Something went wrong");
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (username, password, event) => {
    event.preventDefault();
    let data = new FormData();
    data.append("username", username);
    data.append("password", password);
    setIsLoading(true);
    try {
      response = await axios.post(`${baseURL}/customerlogin`, data);
      if (response.status === 200) {
        sessionStorage.setItem("userEmail", username);
        setIsLoggedIn(true);

        toast.success("OTP sent to your email");
        navigate("/otp");
      }else {
        toast.error("Something went wrong");
        console.log(response);
      }
    } catch (error) {
      toast.error("Invalid credentials");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const passwordReset = async (email, event) => {
    event.preventDefault();
    let data = {email: email};
    setIsLoading(true);
    try {
      response = await axios.post(`${baseURL}/password/customer/request`, data);
      if (response.status === 201) {
        sessionStorage.setItem("userEmail", email);
        toast.success("OTP sent to your email");
        navigate("/otp");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error.response.status === 404) {
        toast.error("Email does not exist");
      } else if (error.response.status === 422) {
        toast.error("Enter a Valid Email");
      } 
      else {
        toast.error("Something went wrong");
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const newPassword = async (password, event) => {
    event.preventDefault();
    let data = {password: password};
    setIsLoading(true);
    passwordToken = sessionStorage.getItem("token");
    try {
      response = await axios.put(`${baseURL}/password/customer/reset`, data, {
        headers: {
          Authorization: `Bearer ${passwordToken}`,
        },
      });
      if (response.status === 200) {
        toast.success("Password reset successful");
        navigate("/login");
      } else {
        console.log(response);
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  
  const onBoardClient = async (
    date_of_pickup,
    date_of_return,
    time_of_pickup,
    time_of_return,
    service,
    rented_item,
    dataPackage,
    payment_method,
    payment_amount,
    payment_status,
    trans_ref,
    
  ) => {
    // event.preventDefault();
    let data = {
      date_of_pickup: date_of_pickup,
      date_of_return: date_of_return,
      time_of_pickup: time_of_pickup,
      time_of_return: time_of_return,
      service: service,
      rented_item: rented_item,
      package: dataPackage,
      payment_method: payment_method,
      payment_amount: payment_amount,
      payment_status: payment_status,
      trans_ref: trans_ref,
    };
    setIsLoading(true);
    token = sessionStorage.getItem("token");
    try {
      response = await axios.post(`${baseURL}/customer/product/`, data,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 201) {
        toast.success("Transaction successful");
        navigate("/dashboard")
      } else {
        toast.error("Transaction Failed");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onBuy = async (
    service,
    rented_item,
    dataPackage,
    payment_method,
    payment_amount,
    payment_status,
    trans_ref,
    
  ) => {
    let data = {
      service: service,
      rented_item: rented_item,
      package: dataPackage,
      payment_method: payment_method,
      payment_amount: payment_amount,
      payment_status: payment_status,
      trans_ref: trans_ref,
    };
    setIsLoadingBuy(true);
    token = sessionStorage.getItem("token");
    try {
      response = await axios.post(`${baseURL}/customer/product/`, data,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 201) {
        toast.success("Transaction successful");
        navigate("/dashboard")
        window.location.reload();
      } else {
        toast.error("Transaction Failed");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingBuy(false);
    }
  };

  const onBoardClientSelfService = async (
    first_name,
    last_name,
    email,
    sex,
    // age,
    country,
    foreign_phone_number,
    host_contact,
    host_location,
    date_of_arrival,
    date_of_departure,
    time_of_arrival,
    time_of_departure,
    rented_item,
    payment_method,
    payment_amount,
    payment_status,
    trans_ref,
  ) => {
    // event.preventDefault();
    let data = new FormData();

      data.append("first_name", first_name);
      data.append("last_name", last_name);
      data.append("email", email);
      data.append("sex", sex);
      data.append("country", country);
      data.append("foreign_phone_number", foreign_phone_number);
      data.append("host_contact", host_contact);
      data.append("host_location", host_location);
      data.append("date_of_arrival", date_of_arrival);
      data.append("date_of_departure", date_of_departure);
      data.append("time_of_arrival", time_of_arrival);
      data.append("time_of_departure", time_of_departure);
      data.append("rented_item", rented_item);
      data.append("payment_method", payment_method);
      data.append("payment_amount", parseFloat(payment_amount));
      data.append("payment_status", payment_status);
      data.append("trans_ref", trans_ref);
    setIsLoading(true);
    try {
      response = await axios.post(`${baseURL}/selfservice/customer/`, data,{
      });
      if (response.status === 201) {
        toast.success("Transaction successful");
        if (window.confirm("Thank you for using our service. If you would like to have an account with us so you can manage your subscriptions, click ok.")) {
          navigate('/register');
        } else {
          navigate('/');
        }
      } else {
        toast.error("Transaction Failed");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = () => { 
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("whereToGo");
    localStorage.removeItem("pathName");
    setIsLoggedIn(false);
    setUserInfo(null);
    navigate("/");
    window.location.reload();
  };

  

    const getData = async () => {
        try {
            const response = await axios.get(`${baseURL}/restsimproduct/`,{
                headers:{
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }
            });
            if (response.status === 201){
                setProductData(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getData();
        //eslint-disable-next-line
    },[]);


  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isLoggedIn,
        isLoadingBuy,
        userInfo,
        baseURL,
        onBoardClient,
        onBoardClientSelfService,
        onBuy,
        register,
        verify,
        login,
        passwordReset,
        newPassword,
        productData,
        logout,
        publicKey,
        secretKey,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
