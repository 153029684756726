import React, {useState, useContext, useEffect} from 'react'
import passimg from '../img/passReset.jpg';
// import bg from "../img/bg2.png";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';



export default function PasswordReset() {
    const [email, setEmail] = useState("");
    const {passwordReset, isLoading} = useContext(AuthContext);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const whereToGo = localStorage.getItem('pathName');

    useEffect(() => {
        setIsFormFilled(email !== '');
    }, [email]);

    const handleSubmit = (e) => {
        e.preventDefault();
        localStorage.setItem("pathName", window.location.pathname);
        passwordReset( email, e);
    }


    useEffect(() => {
        sessionStorage.removeItem('token');
    }, [])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    return (
        <div className="main-container form-inner">
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
        
        <div className="login-container">
            <header className=" imgLogo" >
                <img src={passimg} alt="logo" />
            </header>
            <h2 className="title">Email Verification</h2>
            <form onSubmit={handleSubmit} >
                <TextField
                    type='email'
                    label="Email address"
                    placeholder='Email address'
                    variant="outlined"
                    fullWidth
                    required
                    className='mb-3'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <Button disabled={!isFormFilled}  variant="contained" type='submit'  fullWidth className="mb-3">Submit</Button>
            </form>
            <div className="text-center">
                {whereToGo === '/esim' | whereToGo === '/simcard' | whereToGo === 'wifi' | whereToGo === 'power-bank' ? (
                    <Link to="/dashboard" className="text-decoration-none">Return to Dashboard</Link>
                )  : (
                    <Link to="/login" className="text-decoration-none">Return to Login</Link>
                )}
            </div>
            
        </div>
        {/* <div className="watermark">
            <img src={bg} alt="watermark" />
        </div> */}
        </div>
    )
}