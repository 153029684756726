import React, {useState, useEffect, useContext} from 'react'
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ToastService from "react-material-toast";
import { AuthContext } from '../context/AuthContext';

const toast = ToastService.new({
  place: "topRight",
  duration: 1,
  maxCount: 1,
});

export default function Referrals() {
  const token = sessionStorage.getItem("token")
  const {baseURL} = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [point, setPoint] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [referralPoint, setReferralPoint] = useState("");
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isFormFilled2, setIsFormFilled2] = useState(false);



  useEffect(() => {
    setIsFormFilled(email !== '');
}, [email]);


useEffect(() => {
    setIsFormFilled2(point !== '');
}, [point]);

  const getReferrals = async () => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    setLoading(true);
    try{
        const res = await axios.get(`${baseURL}/customer/`,config)
        if(res.status === 200){
            setData(res.data.referrals)
            setReferralPoint(res.data.referral_point)
        }else{
            toast.error("Something Went Wrong")
        }
    }catch(err){
        console.log(err)
    }
    finally{
        setLoading(false);
    }
}

useEffect(() => {
    getReferrals()
    //eslint-disable-next-line
}, [])


const handleReferral = async (event) => {
  event.preventDefault();
  const config = {
    headers: {
        'Authorization': `Bearer ${token}`
    }
}
setLoading(true);
let newData = {
  email: email
};
try{
    const res = await axios.post(`${baseURL}/customer/refer`,newData,config)
    if(res.status === 200){
        toast.success("Referral Sent Successfully")
        getReferrals()
        setEmail("")
    }else{
        // console.log(res.status)
        toast.error("User is already registered")
    }
}catch(err){
    console.log(err)
}
finally{
    setLoading(false);
}}

const handleRedeem = async (event) => {
  event.preventDefault();
  const config = {
    headers: {
        'Authorization': `Bearer ${token}`
    }
}
setLoading(true);
let newData = {
  point: parseInt(point)
};
try{
    const res = await axios.post(`${baseURL}/customer/redeem`,newData,config)
    if(res.status === 200){
        toast.success("Points Redeemed Successfully")
        getReferrals()
        setPoint("")
    }else{
        toast.error("Something Went Wrong")
    }
}catch(err){
    console.log(err)
}
finally{
    setLoading(false);
    
}}



const sortedData = [...data].sort((a, b) => b.id - a.id);
  
  if (data.length === 0) {
    return (
        <div className='mt-5' >
          <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='text-end mt-3' >
              <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Invite a Friend</button>
            </div>
            <h1 className='text-center p-5' >No data to display</h1>
            
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Refer a Friend</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <TextField
                            type='email'
                            label="Email"
                            placeholder='Email'
                            variant="outlined"
                            fullWidth
                            required
                            className='mb-3'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button disabled={!isFormFilled}  type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {handleReferral(event)}} >Submit</button>
                    </form>
                </div>
                </div>
            </div>
            </div>
        </div>
      )
  }else{
    return (
        <div className='mt-5' >
          <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='text-end' >
              <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Invite a Friend</button>
            </div>
            <h2>My Referrals</h2>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">Email Address</th>
                        <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.referred_customer_email}</td>
                            <td>{item.created_at.slice(0, 10)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='text-end' >
              <h3>Total Referral Amount: GH₵ {referralPoint}</h3>
              {/* <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#redeem" >Redeem Points</button> */}
            </div>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Refer a Friend</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <TextField
                            type='email'
                            label="Email"
                            placeholder='Email'
                            variant="outlined"
                            fullWidth
                            required
                            className='mb-3'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button disabled={!isFormFilled}  type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {handleReferral(event)}} >Submit</button>
                    </form>
                </div>
                </div>
            </div>
            </div>

            <div className="modal fade" id="redeem" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Redeem your points</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form>
                        <TextField
                            type='number'
                            label="Enter Points to Redeem"
                            placeholder='Points to Redeem'
                            variant="outlined"
                            fullWidth
                            required
                            className='mb-3'
                            value={point}
                            onChange={(e) => setPoint(e.target.value)}
                        />
                        <button disabled={!isFormFilled2}  type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {handleRedeem(event)}} >Submit</button>
                    </form>
                </div>
                </div>
            </div>
            </div>
            
        </div>
    )
  }  



}