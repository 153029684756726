import React, {useState, useContext, useEffect} from 'react'
// import bg from "../img/bg2.png";
import newimg from "../img/newPass.jpg";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../context/AuthContext';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ToastService from "react-material-toast";

const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

export default function NewPassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const {newPassword, isLoading} = useContext(AuthContext);
    const [showPassword, setShowPassword] = useState(false);
    const [isFormFilled, setIsFormFilled] = useState(false);


    useEffect(() => {
        setIsFormFilled(password !== '' && confirmPassword !== '');
    }, [password, confirmPassword]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const checkPasswordStrength = () => {
        /* eslint-disable */
        const lengthRegex = /.{8,}/;
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const numberRegex = /\d/;
        const specialCharRegex = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/;
        const commonPasswordRegex = /^(?=.*[Pp][Aa][Ss][Ss])(?=.*[Ww][Oo][Rr][Dd])|^(?=.*[0-9])$/;
        
        // Check password against criteria
        if (!lengthRegex.test(password)) {
            return 'Password must be at least 8 characters long';
        } else if (!uppercaseRegex.test(password)) {
            return 'Password must contain at least one uppercase letter';
        } else if (!lowercaseRegex.test(password)) {
            return 'Password must contain at least one lowercase letter';
        } else if (!numberRegex.test(password)) {
            return 'Password must contain at least one number';
        } else if (!specialCharRegex.test(password)) {
            return 'Password must contain at least one special character';
        } else if (commonPasswordRegex.test(password)) {
            return 'Password is too common';
        } else {
            return 'Password strong';
        }
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }else if(checkPasswordStrength() !== "Password strong"){
            toast.error("Password is not strong enough!");
            return;
        }else{
           newPassword( password, e); 
        }
        
    }

    

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); 
            handleSubmit(e);
        }
    };

    return (
        <div className="main-container form-inner">
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
        
        <div className="login-container">
            <header className=" imgLogo" >
                <img src={newimg} alt="logo" />
            </header>
            <h2 className="title">New Password</h2>
            <form onSubmit={handleSubmit} >
            <TextField 
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    placeholder="Password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined" 
                    required
                    fullWidth
                    className="mb-3"
                    onKeyDown={handleKeyDown}
                />
                <TextField 
                    type={showPassword ? 'text' : 'password'}
                    label="Confirm Password"
                    placeholder="Confirm Password" 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    variant="outlined" 
                    required
                    fullWidth
                    className="mb-3"
                    onKeyDown={handleKeyDown}
                />
                <div>
                    <p >{checkPasswordStrength()}</p>
                </div>
                <Button disabled={!isFormFilled} variant="contained" type='submit'  fullWidth className="mb-3">Submit</Button>
            </form>
                <FormControlLabel 
                    control={
                    <Switch onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} />
                    } 
                    label="Show password" 
                />

            <div>
            </div>
            
        </div>
        {/* <div className="watermark">
            <img src={bg} alt="watermark" />
        </div> */}
        </div>
    )
}