import React, {useState, useContext, useEffect} from 'react'
import otpimg from '../img/otp.jpg';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../context/AuthContext';
import OtpInput from 'react-otp-input';



export default function Otp() {
    const [otp, setOtp] = useState('');
    const {isLoading, verify, passwordReset} = useContext(AuthContext);
    const isOtpComplete = otp.length === 6;
    const email = sessionStorage.getItem('userEmail');
    const username = email
    const [seconds, setSeconds] = useState(60);
    const [buttonEnabled, setButtonEnabled] = useState(false);

    useEffect(() => {
        if (seconds > 0) {
          const interval = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds - 1);
          }, 1000);
    
          return () => clearInterval(interval);
        } else {
          setButtonEnabled(true);
        }
      }, [seconds]);

    const handleOtpChange = (otp) => {
        setOtp(otp);
      };

    const handleVerify = (e) => {
        e.preventDefault();
        verify( email, parseInt(otp), e);
    }

    const handleResend = (e) => {
        e.preventDefault();
        passwordReset(username, e);
        setSeconds(60);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleVerify(e);
        }
    };
    return (
        <div className="main-container form-inner">
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
        
        <div className="login-container">
            <header className=" imgLogo" >
                <img src={otpimg} alt="logo" />
            </header>
            <div className='text-center' >
                <h2 className="title">Enter OTP sent to your email</h2>
            </div>
            <div className='p-3' >
                <OtpInput
                    containerStyle={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    inputStyle={{width: '2rem', height: '2rem', margin: '0 1rem', fontSize: '1.5rem', borderRadius: 4, border: '1px solid rgba(0,0,0,0.3)'}}
                    shouldAutoFocus={true}
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <div className='mt-3' >
                <p className='text-center'>Didn't receive OTP? Resend OTP in {seconds} seconds <button className='btn text-primary fw-bold' onClick={(e) => { handleResend(e) } } disabled={!buttonEnabled} >Resend</button></p>
            </div>
            <div className='mt-3 text-center'>
                <button className="btn btn-primary" onClick={handleVerify} disabled={!isOtpComplete} >Verify</button>
            </div>
            
        </div>
        {/* <div className="watermark">
            <img src={bg} alt="watermark" />
        </div> */}
        </div>
    )
}