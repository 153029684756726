import React, {useContext} from 'react'
import logo from '../img/logoheader.png';
import { AuthContext } from '../context/AuthContext';


export default function NavBars() {
  const token = sessionStorage.getItem('token');
  const { logout } = useContext(AuthContext);

  return (
    <nav className="navbar navbar-expand-lg bg shadow fixed-top">
      <div className="container">
        <a className="navbar-brand" href="/">
            <img src={logo} alt="logo" className="logohead" />
            <span className='ms-3 text-white' >Buy SIM & Connect GH</span>
        </a>
        <button className="navbar-toggler bg-white border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse text-uppercase" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="./#about">About Us</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="./#pricing">Pricing</a>
            </li>
            <li className="nav-item dropdown">
              <button className="nav-link dropdown-toggle text-uppercase" data-bs-toggle="dropdown" aria-expanded="false">
                Sims
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/esim">E-Sim</a></li>
                <li><a className="dropdown-item" href="/simcard">Sim Card</a></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <button className="nav-link dropdown-toggle text-uppercase" data-bs-toggle="dropdown" aria-expanded="false">
                Electronic Devices
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/wifi">Wifi Device</a></li>
                <li><a className="dropdown-item" href="/power-bank">Power Bank</a></li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="./#contact">Contact Us</a>
            </li>
          </ul>
          <div className='d-flex' >
            <>
            {token ? 
            <div>
              <a href="/dashboard" >
                <button className='btn bg-white text-uppercase fw-bold' >Dashboard</button>
              </a>
              <button className='btn bg-white text-uppercase fw-bold ms-2' onClick={logout} >Logout</button>
            </div>
            
            :
            <div>
                <a href="/selfservice" className='btn bg-white text-uppercase fw-bold' >Self Service</a>
                <a href="/login" className='btn bg-white text-uppercase fw-bold ms-2' >Login</a>
            </div>
            }
            </>
          </div>
        </div>
      </div>
    </nav>
  )
}
