import React,{useState, useEffect, useContext} from 'react'
// import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import axios from "axios";
import ToastService from 'react-material-toast';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from '../context/AuthContext';

const toast = ToastService.new({
  place: "topRight",
  duration: 1,
  maxCount: 1,
});


export default function Profile() {
  const token = sessionStorage.getItem("token")
  const {baseURL} = useContext(AuthContext)
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    contact: '',
    age: ''
  })

  const getCustomer = async () => {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    setLoading(true)
    try{
      const res = await axios.get(`${baseURL}/customer/`,config) 
      if(res.status === 200){
        setData(res.data)
        setValues({...values, first_name: res.data.first_name, last_name: res.data.last_name, email: res.data.email, contact: res.data.contact, age: res.data.age})
      }else{
        toast.error("Something Went Wrong")
      }
    }catch(err){
        console.log(err)
      }
      finally{
        setLoading(false)
      }
  };

  useEffect(() => {
    getCustomer()
     //eslint-disable-next-line
  },[])


  const updateProfile = async (event) => {
    event.preventDefault();
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    setLoading(true)
    let newData = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      contact: values.contact,
      age: parseInt(values.age),
      updated_at: new Date()
    };
    
    try{
      const res = await axios.patch(`${baseURL}/customer/`,newData,config)
      if(res.status === 200){
        toast.success("Profile Updated Successfully")
        getCustomer()
      }else{
        toast.error("Something Went Wrong")
      }
    }catch(err){
      console.log(err)
    }
    finally{
      setLoading(false)
    }
  }

  return (
    <div>
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
      >
              <CircularProgress color="inherit" />
      </Backdrop>
    <div className="contain">
            <div className="profile-card">
                <div className="profile-top"></div>
                <div className="profile-pic" style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}}>
                    <Avatar alt="Profile Pic" className='propic' src={data.profile_photo} sx={{ width: 150, height: 150 }}/>
                </div>
                <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                        >
                            <CircularProgress color="inherit" />
                    </Backdrop>
                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                  <div  >
                    <h5>Name:</h5>
                    <h5>Email:</h5>
                    <h5>Age:</h5>
                    <h5>Contact:</h5>
                    <h5>Gender:</h5>
                    <h5>Country:</h5>
                  </div>
                  <div className='text-end' >
                    <h5>{data.first_name} {data.last_name}</h5>
                    <h5>{data.email}</h5>
                    <h5>{data.age}</h5>
                    <h5>{data.contact}</h5>
                    <h5>{data.sex}</h5>
                    <h5>{data.country}</h5>
                  </div>
                    

                </div>
                <div className="profile-btn text-center py-3">
                <h5>To update your details kindly send an email to support through <a href="mailto:info@rentsimconnect.com">info@rentsimconnect.com</a> </h5>
                    {/* <Button variant="contained" color="primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Edit</Button> */}
                </div>
            </div>
        </div>
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Update Your Profile</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                        >
                            <CircularProgress color="inherit" />
                    </Backdrop>
                    <form>
                    <TextField 
                        type="text"
                        label="First Name"
                        value={values.first_name}
                        onChange={e => setValues({...values, first_name: e.target.value})}
                        variant="outlined" 
                        required
                        fullWidth
                        className="mb-3"
                    />
                    <TextField 
                        type="text"
                        label="Last Name"
                        value={values.last_name}
                        onChange={e => setValues({...values, last_name: e.target.value})}
                        variant="outlined" 
                        required
                        fullWidth
                        className="mb-3"
                    />
                    <TextField 
                      type="email"
                      label="Email"
                      value={values.email}
                      onChange={e => setValues({...values, email: e.target.value})}
                      variant="outlined" 
                      required
                      fullWidth
                      className="mb-3"
                    />
                    <TextField 
                      type="number"
                      label="Age"
                      value={values.age}
                      onChange={e => setValues({...values, age: e.target.value})}
                      variant="outlined" 
                      required
                      fullWidth
                      className="mb-3"
                    />
                        <TextField 
                        type="text"
                        label="Phone Number"
                        value={values.contact}
                        onChange={e => setValues({...values, contact: e.target.value})}
                        variant="outlined" 
                        required
                        fullWidth
                        className="mb-3"
                    />
                        <button  type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {updateProfile(event)}} >Update
                        </button>
                    </form>
                </div>
                </div>
            </div>
        </div>
        </div>
  )
}