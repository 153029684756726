import React, {useState, useContext, useEffect} from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import {AuthContext} from '../context/AuthContext';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ToastService from "react-material-toast";
import { usePaystackPayment } from 'react-paystack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import bg from "../img/bg2.png";
import NavBars from "../components/NavBars";
import axios from "axios";

const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

function SelfService() {
    const [first_name, setFirstname] = useState("");
    const [last_name, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [sex, setSex] = useState("male");
    const [country, setCountry] = useState("");
    // const [age, setAge] = useState("");
    // const [passport_number, setPassport] = useState("");
    const [foreign_phone_number, setForeignPhone] = useState("");
    const [host_contact, setHostContact] = useState("");
    const [host_location, setHostLocation] = useState("");
    // const [file, setFile] = useState("");
    const [date_of_arrival, setArrivalDate] = useState();
    const [time_of_arrival, setArrivalTime] = useState();
    const [date_of_departure, setDepartureDate] = useState();
    const [time_of_departure, setDepartureTime] = useState();
    const [service, setService] = useState("");
    const [rented_item, setRentedItem] = useState("");
    // const [dataPackage, setDataPackage] = useState("");
    const [paymentMode, setPaymentMode] = useState("");
    const [ref, setTransRef] = useState("");
    const [status, setPaymentStatus] = useState("");
    const {onBoardClientSelfService, isLoading, productData, baseURL, publicKey, secretKey} = useContext(AuthContext);
    const [open, setOpen] = React.useState(false);
    const [openCash, setOpenCash] = useState(false);
    const [openPOS, setOpenPOS] = useState(false);
    const [rates, setRates] = useState([]);
    // const [isFormFilled, setIsFormFilled] = useState(false);

    var selectedProductId;
    const [data, setData] = useState([]);


    const handleServiceChange = (event) => {
        const selectedProductName = event.target.value;
        const selectedProduct = productData.find(product => product.name === selectedProductName);
    
        if (selectedProduct) {
          selectedProductId = selectedProduct.id;
          getProductById(selectedProductId);
        }
        setService(event.target.value);
    };

    const getProductById = async (selectedProductId) => {
        try {
            const response = await axios.get(`${baseURL}/restsimproduct/${selectedProductId}`,{
                headers:{
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }
            });
            if (response.status === 201){
                let data = response.data
                setData(response.data);
                setRentedItem(data.name);
            }
        } catch (error) {
            console.log(error);
        }
    }
      

//Modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

    const handleOpenCash = () => {
        setOpenCash(true);
    };

    const handleCloseCash = () => {
        setOpenCash(false);
    };
    const handleOpenPOS = () => {
        setOpenPOS(true);
    };

    const handleClosePOS = () => {
        setOpenPOS(false);
    };


//Modal

  const handlePaymentMethod = (event) => {
    setPaymentMode(event.target.value);
  };

  const handleArrivalDate = (event) => {
    setArrivalDate(event);
  };


  const handleArrivalTime = (event) => {
    setArrivalTime(event);
  };

  const handleDepartureDate = (event) => {
    setDepartureDate(event);
  };

  const handleDepartureTime = (event) => {
    setDepartureTime(event);
  };

    // currency convertor function
  const getExchangeRates = async () => {
    await fetch("https://v6.exchangerate-api.com/v6/3dbf859c5b891497ef1e519e/pair/GHS/USD", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setRates(res.conversion_rate);
      })
      .catch((err) => {
        console.log(err);
      });
  };


   // other variables
  let payment_method = paymentMode;
  let amount_GHS = 0.0;
  let amount = 0.00;
  let payment_amount = 0.0;
  let payment_status = status;
  let trans_ref = ref;
  let discount;
  let actualAmount = 0.0;

  function amountPayable() {
    amount =data.duration <= 7 ? (data.price * data.duration).toFixed(2) :
            data.duration <= 14 ? (data.price * data.duration).toFixed(2) :
            data.duration <= 21 ? (data.price * data.duration).toFixed(2) :
            (data.price * data.duration).toFixed(2);
  }
  amountPayable();
    actualAmount = amount;


    function calcDiscount(){
        if(data.duration <= 7){
            discount = ((data.price * data.discount) * data.duration).toFixed(2);
            payment_amount = ((amount - discount)).toFixed(2);
        }else if(data.duration <= 14){
                discount = ((data.price * data.discount) * data.duration).toFixed(2);
                payment_amount = ((amount - discount)).toFixed(2);
        }else if(data.duration <= 21){
                discount = ((data.price * data.discount) * data.duration).toFixed(2);
                payment_amount = ((amount - discount)).toFixed(2);
        }else{
                discount = ((data.price * data.discount) * data.duration).toFixed(2);
                payment_amount = ((amount - discount)).toFixed(2);
            }
    }

    calcDiscount();
    amount_GHS = (payment_amount / rates).toFixed(2);
    let finalAmount = amount_GHS * 100;
    
  useEffect(() => {
    getExchangeRates();
    //eslint-disable-next-line
  }, [payment_amount]);

  
  function afterPayment(event) {
    event.preventDefault();
    if ( payment_amount === "NaN"){
            toast.error("Time and Date fields cannot be empty");
    }else if(first_name === '' || last_name === '' || email === '' || date_of_arrival === '' || time_of_arrival === '' || date_of_departure === '' || time_of_departure === '' || rented_item === '' || paymentMode === '' || host_contact === '' || foreign_phone_number === '' || host_location === ''){
        toast.error("All fields are required!");
    }else if (date_of_arrival === undefined || time_of_arrival === undefined || date_of_departure === undefined || time_of_departure === undefined){
        toast.error("Time and Date fields cannot be empty or have default values");
    }else{
    onBoardClientSelfService(
        first_name,
        last_name,
        email,
        sex,
        country,
        foreign_phone_number,
        host_contact,
        host_location,
        date_of_arrival,
        date_of_departure,
        time_of_arrival,
        time_of_departure,
        rented_item,
        payment_method,
        parseFloat(payment_amount),
        payment_status,
        trans_ref,
        event
    );
    }
  }


   //Paystack
    const config = {
      reference: (new Date()).getTime().toString(),
      email: email,
      amount: finalAmount, 
      publicKey: publicKey,
      secretKey: secretKey,
      currency: 'GHS',
    };

  

  const onSuccess = (reference) => {
    setTransRef(reference.reference);
    setPaymentStatus(reference.status);
    handleClose();
  };

  const onClose = () => {

    console.log('closed')
  }

  const initializePayment = usePaystackPayment(config);
  return (
    <div>
        <NavBars/>
        <div className="main-container">
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
        <header className="mt-5 spacerOB" >
        </header>
        <div className="form-container">
            <form>
                <TextField
                    type='text'
                    label="First Name"
                    placeholder='First Name'
                    fullWidth
                    required
                    className='mb-3'
                    value={first_name}
                    onChange={(e) => setFirstname(e.target.value)}
                    
                />
                <TextField
                    type='text'
                    label="Last Name"
                    placeholder='Last Name'
                    fullWidth
                    required
                    className='mb-3'
                    value={last_name}
                    onChange={(e) => setLastname(e.target.value)}
                    
                />
                <TextField
                    type='email'
                    label="Email Address"
                    placeholder='Email Address'
                    variant="outlined"
                    fullWidth
                    required
                    className='mb-3'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    
                />
                <div className="mb-3">
                    <FormLabel >Sex</FormLabel>
                    <RadioGroup
                    defaultValue="male"
                    >
                        <FormControlLabel value="male" control={<Radio />} label="Male" onClick={() => setSex("male")} />
                        <FormControlLabel value="female" control={<Radio />} label="Female" onClick={() => setSex("female")}/>
                    </RadioGroup>
                </div>
                <TextField
                    type='text'
                    label="Country"
                    placeholder='Country'
                    fullWidth
                    required
                    className='mb-3'
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                />
            
                <div className="input-filed" >
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker
                            label="Date of Arrival"
                            value={date_of_arrival}
                             onChange={handleArrivalDate}
                            renderInput={(params) => <TextField {...params} fullWidth required />}
                            
                        />
                    </LocalizationProvider>
                    
                </div>
                <div className="input-filed">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Time of Arrival"
                            value={time_of_arrival}
                            onChange={handleArrivalTime}
                            renderInput={(params) => <TextField {...params} fullWidth required/>}
                        />
                    </LocalizationProvider>
                </div>
                <div className="input-filed" >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Date of Departure"
                            value={date_of_departure}
                             onChange={handleDepartureDate}
                            renderInput={(params) => <TextField {...params} fullWidth required/>}
                        />
                    </LocalizationProvider>
                    
                </div>
                <div className="input-filed">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Departure Time"
                            value={time_of_departure}
                            onChange={handleDepartureTime}
                            renderInput={(params) => <TextField {...params} fullWidth required/>}
                        />
                    </LocalizationProvider>
                </div>
                <div className="input-filed" >
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" >Select a product</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={service}
                        label="Select a package"
                        onChange={handleServiceChange}
                        required
                        >
                        {productData.map((product) => (
                            <MenuItem key={product.id} value={product.name}>
                            {product.name.replace(/\s\d+$/, '')} {product.duration} days
                            </MenuItem>
                        ))}
                        
                        </Select>
                    </FormControl>

                </div>
                <div className="mb-3">
                    <FormLabel  >Foreign Phone Number</FormLabel>
                    <PhoneInput
                        placeholder="Phone Number"
                        defaultCountry="GH"
                        className="phone-input"
                        value={foreign_phone_number}
                        onChange={setForeignPhone}
                        limitMaxLength
                    />
                </div>
                <div className="mb-3">
                    <FormLabel >Host Contact</FormLabel>
                    <PhoneInput
                        placeholder="Phone Number"
                        defaultCountry="GH"
                        className="phone-input"
                        value={host_contact}
                        onChange={setHostContact}
                        limitMaxLength
                    />
                </div>
                <TextField
                    type='text'
                    label="Host Location/Address"
                    placeholder='Host Location'
                    fullWidth
                    required
                    className='mb-3'
                    value={host_location}
                    onChange={(e) => setHostLocation(e.target.value)}
                />
                {/* <div className="mb-3">
                    <FormLabel  >Attach Copy of Your Passport</FormLabel>
                    <TextField  type="file" accept="image" capture="environment" onChange={(e) => setFile(e.target.files[0])}  fullWidth />
                </div> */}
                <div className="input-filed">
                    <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" >Select Method of Payment</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={paymentMode}
                            label="Select Method of Payment"
                            onChange={handlePaymentMethod}
                            >
                            <MenuItem value="Cash" onClick={handleOpenCash} >Cash</MenuItem>
                            <MenuItem value="POS" onClick={handleOpenPOS} >POS</MenuItem>
                            <MenuItem value="Card" onClick={handleClickOpen} >Card</MenuItem>
                            <MenuItem value="Mobile Money" onClick={handleClickOpen}>Mobile Money</MenuItem>
                            </Select>
                        </FormControl>
                </div>
            </form>
            <div className="input-filed subBtn">
                <button type="submit" className="btn btn-primary w-100" onClick={(e) => {afterPayment(e)}} >Submit</button>
            </div>
                
        </div>
        <div className="watermark">
            <img src={bg} alt="watermark" />
        </div>
        {/* Modal  */}
                <Dialog
                    open={open}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Confirm to Make Payment"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Unit price: <span className="unit-price">${data.price}</span> per day
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Period: <span className="period" >{data.duration}</span> days
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Actual Amount: <span className="unit-price" > ${actualAmount} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Discount: <span className="unit-price">${discount}</span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Dollars: <span className="amount" > ${payment_amount} </span> 
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleClose();
                    }}>Cancel</Button>
                    <Button onClick={()=>{initializePayment(onSuccess, onClose)}} autoFocus>
                        Make Payment
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openCash}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Cash Payment Method"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                         You have selected Cash as your payment method
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Unit price: <span className="unit-price">${data.price}</span> per day
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Period: <span className="period" >{data.duration}</span> days 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Actual Amount: <span className="unit-price" > ${actualAmount} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Discount: <span className="unit-price">${discount}</span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Dollars: <span className="amount" > ${payment_amount} </span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Please click OK below to continue
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleCloseCash();
                    }}>Cancel</Button>
                    <Button onClick={(e)=>{
                        setPaymentMode("Cash");
                        handleCloseCash();
                    }} autoFocus>
                        Ok
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openPOS}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"POS Payment Method"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                         You have selected POS as your payment method
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Unit price: <span className="unit-price">${data.price}</span> per day
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Period: <span className="period" >{data.duration}</span> days 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Actual Amount: <span className="unit-price" > ${actualAmount} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Discount: <span className="unit-price">${discount}</span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Dollars: <span className="amount" > ${payment_amount} </span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Please click OK below to continue
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleClosePOS();
                    }}>Cancel</Button>
                    <Button onClick={(e)=>{
                        setPaymentMode("Cash");
                        handleClosePOS();
                    }} autoFocus>
                        Ok
                    </Button>
                    </DialogActions>
                </Dialog>
        </div>

        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Transaction Successful</h1>
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                </div>
                <div className="modal-body">
                    <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                        >
                            <CircularProgress color="inherit" />
                    </Backdrop>
                    <h5>Thank you for using our service. If you would like to have an account with us so you can manage your subscriptions, click on <span className="text-primary" >Get Started</span> below</h5>
                    
                </div>
                <div className="modal-footer">
                    <a href="/" type="button" className="btn btn-danger">Dismis</a>
                    <a href="/login" type="button" className="btn btn-primary">Get Started</a>
                </div>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default SelfService