import React, {useState, useContext} from 'react'
import regLogo from '../img/signUp.jpg';
// import bg from "../img/bg2.png";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import ReCAPTCHA from "react-google-recaptcha";
import ToastService from "react-material-toast";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

export default function Register() {
    const [first_name, setFirstname] = useState("");
    const [last_name, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [sex, setSex] = useState("male");
    const [country, setCountry] = useState("");
    // const [dob, setDob] = useState();
    // const [passport_number, setPassport] = useState("");
    const [contact, setContact] = useState();
    // const [passport_photo, setPassportImage] = useState("");
    const [profile_photo, setProfileImage] = useState("");
    const [referral_code, setReferral] = useState("");
    const [password, setPassword] = useState("");
    const {register, isLoading} = useContext(AuthContext);
    const [openTerms, setOpenTerms] = useState(false);
    const [readTerms, setReadTerms] = useState("");
    const [isChecked, setIsChecked] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    // const formatDate = (date) => {
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const day = String(date.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    //   };


    //   const handleDobChange = (e) => {
    //     setDob(e);
    //   };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        if (!isChecked) {
          setReadTerms('read and acceptted');
        } else {
          setReadTerms('');
        }
      };

    //   const [verified, setVerified] = useState(false);

        function onChange(value) {
            console.log("Captcha value:", value);
            // setVerified(true);
        }

    const isInputFilled = readTerms.trim() !== '';

    const handleOpenTerms = () => {
        setOpenTerms(true);
    };

    const handleCloseTerms = () => {
        setOpenTerms(false);
    };

    const onClose = () => {

        console.log('closed')
      }

      const checkPasswordStrength = () => {
        /* eslint-disable */
        const lengthRegex = /.{8,}/;
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const numberRegex = /\d/;
        const specialCharRegex = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/;
        const commonPasswordRegex = /^(?=.*[Pp][Aa][Ss][Ss])(?=.*[Ww][Oo][Rr][Dd])|^(?=.*[0-9])$/;
        
        // Check password against criteria
        if (!lengthRegex.test(password)) {
            return 'Password must be at least 8 characters long';
        } else if (!uppercaseRegex.test(password)) {
            return 'Password must contain at least one uppercase letter';
        } else if (!lowercaseRegex.test(password)) {
            return 'Password must contain at least one lowercase letter';
        } else if (!numberRegex.test(password)) {
            return 'Password must contain at least one number';
        } else if (!specialCharRegex.test(password)) {
            return 'Password must contain at least one special character';
        } else if (commonPasswordRegex.test(password)) {
            return 'Password is too common';
        } else {
            return 'Password is strong';
        }
      }



    const handleSubmit = (e) => {
            e.preventDefault();
            // const fmtDob = formatDate(new Date(dob));
            if (checkPasswordStrength() !== "Password is strong"){
                toast.error("Password is not strong enough!")
            }else{

            register(
            first_name,
            last_name,
            email,
            sex,
            country,
            // fmtDob,
            // passport_number,
            contact,
            // passport_photo,
            profile_photo,
            referral_code,
            password,
            e
        );
        }
    }
    

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if(!isChecked){
                e.preventDefault();
                toast.error("Please read and accept the terms and conditions")
            }else{
                e.preventDefault();
                handleSubmit(e)
            }
            
        }
    };

  return (
    <div className="main-container form-inner">
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
        
        <div className="signup-container mt-5">
            <header className=" imgLogo" >
                <img src={regLogo} alt="logo" />
            </header>
            <h2 className="title">Sign Up</h2>
            <form onSubmit={handleSubmit} >
                <TextField
                    type='text'
                    label="First Name"
                    placeholder='First Name'
                    fullWidth
                    required
                    className='mb-3'
                    value={first_name}
                    onChange={(e) => setFirstname(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <TextField
                    type='text'
                    label="Last Name"
                    placeholder='Last Name'
                    fullWidth
                    required
                    className='mb-3'
                    value={last_name}
                    onChange={(e) => setLastname(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <TextField
                    type='email'
                    label="Email Address"
                    placeholder='Email Address'
                    variant="outlined"
                    fullWidth
                    required
                    className='mb-3'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <div className="mb-3">
                    <FormLabel >Sex</FormLabel>
                    <RadioGroup
                    defaultValue="male"
                    onKeyDown={handleKeyDown}
                    >
                        <FormControlLabel value="male" control={<Radio />} label="Male" onClick={() => setSex("male")} />
                        <FormControlLabel value="female" control={<Radio />} label="Female" onClick={() => setSex("female")}/>
                    </RadioGroup>
                </div>
                
                <TextField
                    type='text'
                    label="Country"
                    placeholder='Country'
                    fullWidth
                    required
                    className='mb-3'
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <div className="mb-3">
                    <FormLabel  >Contact</FormLabel>
                    <PhoneInput
                        placeholder="Phone Number"
                        defaultCountry="GH"
                        className="phone-input"
                        value={contact}
                        onChange={setContact}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                
                <div className="mb-3">
                    <FormLabel  >Upload Profile Picture</FormLabel>
                    <TextField  type="file" accept="image" capture="environment" onChange={(e) => setProfileImage(e.target.files[0])}   fullWidth onKeyDown={handleKeyDown} />
                </div>
                <TextField
                    type='text'
                    label="Referral Code (If any)"
                    placeholder='Referral Code'
                    fullWidth
                    className='mb-3'
                    value={referral_code}
                    onChange={(e) => setReferral(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <TextField 
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    placeholder="Password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }}
                    
                    variant="outlined" 
                    required
                    fullWidth
                    className="mb-3"
                    onKeyDown={handleKeyDown}
                />
                <div>
                    <p id="passwordStrength">{checkPasswordStrength()}</p>
                </div>
                <ReCAPTCHA
                    className="mb-3"
                    sitekey="6LfCrnInAAAAABlMZ88dRwmy7mMkwEJtMl-BLVDt"
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                  />
                <div className="mb-3" >
                <FormControlLabel required control={<Checkbox  onKeyDown={handleKeyDown} />} label="I have read and accepted the" checked={isChecked} onChange={handleCheckboxChange} />
                <span className="terms" onClick={() => {handleOpenTerms()}} >Terms & Conditions</span>
                </div>
                <Button disabled={!isInputFilled} variant="contained"  type='submit'  fullWidth className="mb-3"  >Submit</Button>
            </form>
            <div className="text-center">
                <span>Already have an account?</span><Link to="/login" className="text-decoration-none">Login</Link>
            </div>
        </div>
        {/* <div className="watermark">
            <img src={bg} alt="watermark" />
        </div> */}
        <Dialog
                    open={openTerms}
                    // onClose={handleClose}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Terms & Conditions"}
                    </DialogTitle>
                    <DialogContent>
                        <h4>Welcome to RENTSIMCONNECT.COM!</h4>
                        <p>These terms and conditions outline the rules and regulations for the use of RENTSIMCONNECT GHANA's Website, located at https://rentsimconnect.com/.</p>
                        <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use RENTSIMCONNECT.COM if you do not agree to take all of the terms and conditions stated on this page.</p>
                        <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of af. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
                        <h5>Cookies</h5>
                        <p>We employ the use of cookies. By accessing RENTSIMCONNECT.COM, you agreed to use cookies in agreement with the RENTSIMCONNECT GHANA's Privacy Policy.</p>
                        <p>Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
                        <h5>License</h5>
                        <p>Unless otherwise stated, RENTSIMCONNECT GHANA and/or its licensors own the intellectual property rights for all material on RENTSIMCONNECT.COM. All intellectual property rights are reserved. You may access this from RENTSIMCONNECT.COM for your own personal use subjected to restrictions set in these terms and conditions.</p>
                        <h5>You must not:</h5>
                        <ul>
                            <li>Republish material from RENTSIMCONNECT.COM</li>
                            <li>Sell, rent or sub-license material from RENTSIMCONNECT.COM</li>
                            <li>Reproduce, duplicate or copy material from RENTSIMCONNECT.COM</li>
                            <li>Redistribute content from RENTSIMCONNECT.COM</li>
                        </ul>
                        <p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Terms and Conditions Generator.</p>
                        <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. RENTSIMCONNECT GHANA does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of RENTSIMCONNECT GHANA,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, RENTSIMCONNECT GHANA shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>
                        <p>RENTSIMCONNECT GHANA reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>
                        <h5>You warrant and represent that:</h5>
                        <ul>
                            <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                            <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
                            <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                            <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                        </ul>
                        <p>You hereby grant RENTSIMCONNECT GHANA a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>
                        <h5>Hyperlinking to our Content</h5>
                        <p>The following organizations may link to our Website without prior written approval:</p>
                        <ul>
                            <li>Government agencies;</li>
                            <li>Search engines;</li>
                            <li>News organizations;</li>
                            <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                            <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li> 
                        </ul>
                        <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>
                        <p>We may consider and approve other link requests from the following types of organizations:</p>
                        <ul>
                            <li>commonly-known consumer and/or business information sources;</li>
                            <li>dot.com community sites;</li>
                            <li>associations or other groups representing charities;</li>
                            <li>online directory distributors;</li>
                            <li>internet portals;</li>
                            <li>accounting, law and consulting firms; and</li>
                            <li>educational institutions and trade associations.</li>
                        </ul>
                        <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of RENTSIMCONNECT GHANA; and (d) the link is in the context of general resource information.</p>
                        <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.</p>
                        <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to RENTSIMCONNECT GHANA. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
                        <p>Approved organizations may hyperlink to our Website as follows:</p>
                        <ul>
                            <li>By use of our corporate name; or</li>
                            <li>By use of the uniform resource locator being linked to; or</li>
                            <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</li>
                        </ul>
                        <p>No use of RENTSIMCONNECT GHANA's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
                        <h5>iFrames</h5>
                        <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>
                        <h5>Content Liability</h5>
                        <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
                        <h5>Reservation of Rights</h5>
                        <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
                        <h5>Removal of links from our website</h5>
                        <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>
                        <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
                        <h5>Disclaimer</h5>
                        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
                        <ul>
                            <li>limit or exclude our or your liability for death or personal injury;</li>
                            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                            <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                            <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                        </ul>
                        <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>
                        <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
                    </DialogContent>
                    <DialogActions>
                    <Button className="py-3"  onClick={()=>{handleCloseTerms()}} autoFocus>
                        OK
                    </Button>
                    </DialogActions>
                </Dialog>
    </div>
   
  )
}
