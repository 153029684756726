import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

export const Footer = () =>{
  // get current year 
  const year = new Date().getFullYear();

  return (
    <Box sx={{ flexGrow: 1 }}  >
      <AppBar position="static" style={{backgroundColor: "#00239F"}}>
        <Toolbar>
        <div className="container social py-2" > 
            <div className="row" >
              <div className="col-md-6" >
                <h6>&copy; {year} Buy SIM & Connect GH - All Rights Reserved</h6>
                <h6>Developed by <a className='text-white' href="https://techieszon.com/" target="_blank" rel="noreferrer">Techieszon</a></h6>
              </div>
              <div className="col-md-6" >
                <div className="social-icons" >
                  <h6>Follow us on:</h6>
                  <a href="https://www.instagram.com/rentsim_connectsgh/" target="_blank" rel="noreferrer"> <InstagramIcon style={{color: "#fff", width:30, height:35}} /> </a>
                  <a href="https://web.facebook.com/profile.php?id=100089488281297" target="_blank" rel="noreferrer"> <FacebookIcon style={{color: "#fff", width:30, height:35}} /> </a>
                  <a href="https://twitter.com/rentsim_connect" target="_blank" rel="noreferrer"> <img src='https://img.icons8.com/ios-filled/30/ffffff/twitterx--v1.png'  alt='X' style={{width:30}} /> </a>
                </div>
              </div>
            </div>
          </div>
          
          
        </Toolbar>
      </AppBar>
    </Box>
  );
}