import React, {useState, useContext} from 'react'
import logo from '../img/logoheader.png';
import MenuIcon from "@mui/icons-material/Menu";
import Home  from '@mui/icons-material/Home';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from "@mui/material/IconButton";
import MoneyIcon from '@mui/icons-material/Money';
import IosShareIcon from '@mui/icons-material/IosShare';
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import Profile from '../screens/Profile';
import Referrals from '../screens/Referrals';
import Transactions from '../screens/Transactions';
import axios from "axios";
import Avatar from '@mui/material/Avatar';
import { AuthContext } from '../context/AuthContext';

export let handleProductClick;
export let handleTransactionClick;
export let userData = ({}); 

export default function DashNav() {
    const [screen, setScreen] = useState('Transactions');
    const token = sessionStorage.getItem('token');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [profile_photo, setProfilePhoto] = useState('');
    const { logout, baseURL } = useContext(AuthContext);
    let data = {};

    const renderScreen = () => {
        switch(screen){
            case 'Profile':
                return <Profile />;
            case 'Referrals':
                return <Referrals />;
            case 'Transactions':
                return <Transactions />;
            default:
                return <Profile />;
        }
    }

    const handleProfileClick = () => {
        setScreen('Profile');
    }

    const handleReferralClick = () => {
        setScreen('Referrals')
    }

    const handleTransactionClick = () => {
        setScreen('Transactions')
    }

    

  const getCustomer = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get( `${baseURL}/customer/`, config);
      if (response.status === 200) {
        data = response.data;
        userData = data;
        setName(data.first_name + " " + data.last_name);
        setEmail(data.email);
        setProfilePhoto(data.profile_photo);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
getCustomer();

  return (
    <div>
    <nav className="navbar navbar-expand-lg bg shadow fixed-top">
        <div className="container">
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"
            >
                <MenuIcon sx={{color: 'white'}}  />
            </IconButton>
            <a className="navbar-brand" href="/">
                <img src={logo} alt="logo" className="logohead" />
                <span className='ms-3 text-white' >Buy SIM & Connect GH</span>
            </a>
        </div>
    </nav>
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            <Avatar alt="Profile Pic" src={profile_photo} />
            </h5>
            <button type="button" className="btn-close bg-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <span className='ms-3 text-white' >{name}</span>
        <span className='ms-3 text-white' >{email}</span>
        <hr style={{color: 'white'}} ></hr>
        <div className="offcanvas-body">
            <nav className="nav flex-column">
                <a className="nav-link sideNavItems dashNavItems" href="/"><Home/> Home</a>
                <p className="nav-link sideNavItems dashNavItems mb-0" data-bs-dismiss="offcanvas" onClick={handleProfileClick} ><AccountCircle/> Profile</p>
                <p className="nav-link sideNavItems dashNavItems mb-0" data-bs-dismiss="offcanvas" onClick={handleTransactionClick}><MoneyIcon/> Transactions</p>
                <p className="nav-link sideNavItems dashNavItems" data-bs-dismiss="offcanvas" onClick={handleReferralClick}><IosShareIcon/> Referrals</p>
                <hr style={{color: 'white'}}></hr>
                <a className="nav-link sideNavItems dashNavItems" href="/password-reset"><LockIcon/>Reset Password</a>
                <p className="nav-link sideNavItems dashNavItems" onClick={logout} ><LogoutIcon/>Logout</p>
            </nav>
        </div>
        </div>
        <div className=' container spacer' >
            {renderScreen()}
        </div>
    </div>
  )
  
}
